import React, { memo } from 'react';
import './loading.css';
import { useSelector } from 'react-redux';
import { AllState } from '../../../../redux/rootType';

export default memo(function Loading() {
  const loadingApi = useSelector(
        (allState: AllState) => (
            allState.CommonReducer.loadingApi
        )
    );

  return loadingApi.length > 0 ? (
    <div className="loading">
      <img src={require('../../../../assets/img/loader.svg')} alt="" />
    </div>
  ) : null;
});

import { applyMiddleware, compose, StoreEnhancer } from 'redux';
import thunk from 'redux-thunk';
import reduxPromise from 'redux-promise'
import { legacy_createStore as createStore} from 'redux'
import { createLogger } from 'redux-logger';
import rootReducer from './rootReducer';
import storageSession from 'redux-persist/lib/storage/session';
// import storage from 'redux-persist/lib/storage';
import persistReducer from 'redux-persist/es/persistReducer';
import persistStore from 'redux-persist/es/persistStore';
let middleware: StoreEnhancer;

const IS_DEV = process.env.NODE_ENV === 'development';

if (IS_DEV) {
    const logger = createLogger();
    middleware = applyMiddleware(thunk, logger);
  } else {
    middleware = applyMiddleware(thunk);
  }

// 持久化的配置对象，指定要持久化的Redux存储的键、存储引擎(sessionStorage)、黑名单
const persistConfig = {
  key: 'charon',
  storage: storageSession,
  blacklist: []
}

const myPersistReducer = persistReducer(persistConfig,rootReducer)
// 合并Reducer的配置
const composeEnhancer = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

// 持久化后的myPersistReducer创建store
const store = createStore(myPersistReducer,composeEnhancer(middleware,applyMiddleware(reduxPromise)))

// 持久器，控制Redux双胎的持久化和恢复过程
const persistor = persistStore(store)

// 导出
export { store, persistor }
import React from 'react';
import { Provider } from 'react-redux';
import GetRoutes from './route/route';
import { PersistGate } from 'redux-persist/lib/integration/react'
import { persistor, store } from './redux/store';
import Loading from './components/web/components/loading/Loading.component';
import { HashRouter as Router } from 'react-router-dom';
import './assets/sass/style.scss';

function App() {
  // const Header = React.lazy(() =>
  //   import('./components/web/components/organisms/header/Header.component')
  // );
  // const Footer = React.lazy(() =>
  //   import('./components/web/components/organisms/footer/Footer.component')
  // );

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Loading />
        
        <Router>
          <React.Suspense fallback={<div>Loading...</div>}>
            {/* <Header /> */}
            <GetRoutes />
            {/* <Footer /> */}
          </React.Suspense>
        </Router>
      </PersistGate>
    </Provider>
  );
}

export default App;

import { produce } from 'immer';
import * as common from '../../models/common/common.model';
import { CommonState } from '../../models/common/common.model';

const defaultState: CommonState = {
  apiLoadingInfo: {
    SAGAPFMS0010IsLoading: false,
    SAGAPFMS0110IsLoading: false,
    SAGAPFMS0120IsLoading: false,

  },
  errors: undefined,
  loadingApi: [],
  session: {
    SAGWBFMS05010: {}
  },

  producer_UserID:'',
  producer_ID:'',
  producer_Name:'',
  producer_ImgLink:''
};

const reducer = (state = defaultState, action: {
  type: string;
  value?: any;
}) =>
  produce(state, (draft) => {
    switch (action.type) {
      case common.SET_SAGWBFMS05010_SESSION:
        draft.session.SAGWBFMS05010 = (action.value);
        break;

      case common.SET_Producer_UserID:
        draft.producer_UserID = action.value;
        break;
      case common.SET_ProducerID:
        draft.producer_ID = action.value;
        break;
      case common.SET_Producer_Name:
        draft.producer_Name = action.value;
        break;
      case common.SET_Producer_ImgLink:
        draft.producer_ImgLink = action.value;
        break;


      case common.SET_LOADING_API:
        draft.loadingApi.push(action.value);
        break;
      case common.SET_SAGAPFMS0010_ISLOADING:
        draft.apiLoadingInfo.SAGAPFMS0010IsLoading = action.value
        break;

      case common.SET_SAGAPFMS0110_ISLOADING:
        draft.apiLoadingInfo.SAGAPFMS0110IsLoading = action.value
        break;

      case common.SET_SAGAPFMS0120_ISLOADING:
        draft.apiLoadingInfo.SAGAPFMS0120IsLoading = action.value
        break;

      case common.SET_LOADING_API_COMPLETE:
        draft.loadingApi = draft.loadingApi.filter((value) => value !== action.value);
        break;
      case common.CLEAR_ERRORS_LIST:
        draft.errors = undefined;
        break;
      default:
        break;
    }
  });

export default reducer;

import React from 'react';
import { useRoutes } from 'react-router-dom'
import RouterPath from './path.route';

const SAGWBFMS05011 = React.lazy(()=> import('../components/web/pages/SAGWBFMS05011.page'))
const SAGWBFMS05010 = React.lazy(()=> import('../components/web/pages/SAGWBFMS05010.page'))

const GetRoutes =()=> useRoutes([
  {
    path: RouterPath.SAGWBFMS05011,
    element:<SAGWBFMS05011/>
  },
  {
    path: RouterPath.SAGWBFMS05010,
    element:<SAGWBFMS05010/>
  },
  {
    path: '/*',
    element:<SAGWBFMS05010/>,
  }
]);

export default GetRoutes;

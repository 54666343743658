import { BadRequestResponseError } from "../Error/Error.model";
export const SET_LOADING_API = 'SET_LOADING_API';
export const SET_LOADING_API_COMPLETE = 'SET_LOADING_API_COMPLETE';
export const CLEAR_ERRORS_LIST = 'CLEAR_ERRORS_LIST';
export interface ApiLoadingInfo {
  // 出荷生産者一覧取得API
  SAGAPFMS0010IsLoading: boolean;
  // 出荷品目情報取得API
  SAGAPFMS0120IsLoading: boolean;
  // 出荷品目登録API
  SAGAPFMS0110IsLoading:boolean
}
export const SET_SAGWBFMS05010_SESSION = 'SET_SAGWBFMS05010_SESSION';
export const SET_SAGAPFMS0010_ISLOADING = 'SET_SAGAPFMS0010_ISLOADING';
export const SET_SAGAPFMS0120_ISLOADING = 'SET_SAGAPFMS0120_ISLOADING';
export const SET_SAGAPFMS0110_ISLOADING = 'SET_SAGAPFMS0110_ISLOADING';

export const SET_Producer_UserID = 'SET_Producer_UserID'
export const SET_ProducerID = 'SET_ProducerID'
export const SET_Producer_Name = 'SET_Producer_Name'
export const SET_Producer_ImgLink = 'SET_Producer_ImgLink'


export const SET_LOGIN_TOKEN = 'SET_LOGIN_TOKEN'
export const SET_ERRORS_LIST = 'SET_ERRORS_LIST';

export interface ErrorInfo {
  info: BadRequestResponseError[];
  datetime: Date;
}
export interface CommonState {
  apiLoadingInfo: ApiLoadingInfo;
  errors?: ErrorInfo;
  loadingApi: string[];
  session:{
    SAGWBFMS05010?:SAGWBFMS05010
  }

  producer_UserID:string;
  producer_ID:string;
  producer_Name:string;
  producer_ImgLink:string;


}
export interface SAGWBFMS05010 {
  tnpCd?:string
}